import accountTypes from './account-types';
import API from '../../../axios/api';
import { ACCOUNT } from '../../../axios/api-urls';

const api = new API();

export const getAccountData = () => api.get(ACCOUNT.getUserData);

export const setAccountData = (data) => ({ type: accountTypes.SET_ACCOUNT_DATA, data });

export const putAccountData = (body) => api.put(ACCOUNT.putUserData, body);

export const postAccountPassword = (body) => api.post(ACCOUNT.postUserPassword, body);

export const getAccountProduct = () => api.get(ACCOUNT.getProductInfo);

export const getCurrentProduct = (id, type) => api.get(`${ACCOUNT.getProductInfo}/${id}?type=${type}`);

export const sendVerificationEmailCode = () => api.get(ACCOUNT.sendVerificationEmailCode);

export const setMethodVerification = (body) => api.post(ACCOUNT.setMethodVerification, body);

export const setVerificationEmailProgerss = (progress) => ({
  type: accountTypes.SET_VERIFICATION_EMAIL_PROGERSS,
  progress,
});

export const setVerificationYubikeyProgress = (progress) => ({
  type: accountTypes.SET_VERIFICATION_YUBIKEY_PROGRESS,
  progress,
});

export const setVerificationOtpProgress = (progress) => ({
  type: accountTypes.SET_VERIFICATION_OTP_PROGRESS,
  progress,
});

export const postActivateSim = (body) => api.post(ACCOUNT.postActivateSim, body);

export const postChangeNumber = (body) => api.post(ACCOUNT.postChangeNumber, body);

export const postActivateESim = (body) => api.post(ACCOUNT.postActivateESim, body);

export const getQr = (id) => api.get(`${ACCOUNT.getQr}${id}`);

export const createSetupIntent = (data) => api.post(ACCOUNT.createSetupIntent, data);

export const getSetupIntentResult = (setupIntentId) => api.get(`${ACCOUNT.getSetupIntentResult}/${setupIntentId}`);

export const getPaymentInformation = (userSimPlanId) => api.get(`${ACCOUNT.getPaymentInformation}/${userSimPlanId}`);

export const getCallHistory = (userSimPlanId, count = 0, page = 1) => api.get(`${ACCOUNT.getCallHistory}/${userSimPlanId}/${count}/${page}`);

export const getSmsHistory = (userSimPlanId, count = 0, page = 1) => api.get(`${ACCOUNT.getSmsHistory}/${userSimPlanId}/${count}/${page}`);

export const getPaymentHistory = (userSimPlanId, count = 0, page = 1) => api.get(`${ACCOUNT.getPaymentHistory}/${userSimPlanId}/${count}/${page}`);

export const getIsUserEligibleForVpn = () => api.get(ACCOUNT.getIsUserEligibleForVpn);

export const getVpnUser = () => api.get(ACCOUNT.getVpnUser);

export const addVpnUser = (data) => api.post(ACCOUNT.addVpnUser, data);

export const getVpnAppLinks = () => api.get(ACCOUNT.getVpnAppLinks);

export const resetVpnUserPassword = (data) => api.post(ACCOUNT.resetVpnUserPassword, data);

export const setVpnUserPassword = (data) => api.post(ACCOUNT.setVpnUserPassword, data);

export const cancelSubscription = (data) => api.post(ACCOUNT.cancelSubscription, {
  id: data.id
});

export const changePlan = (data) => api.put(ACCOUNT.changePlan, data);

export const confirmChangePlan = (data) => api.put(ACCOUNT.confirmChangePlan, data);

export const generateSecretAndGetOtpQr = () => api.get(ACCOUNT.generateSecretAndGetOtpQr);

export const changeToNewDeviceGetQr = (data) => api.post(ACCOUNT.changeToNewDeviceGetQr, data);

export const makePaymentForExpiredSim = (data) => api.post(ACCOUNT.makePaymentForExpiredSim, data);
